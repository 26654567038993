import {Box, Typography} from "@mui/material";
import * as React from "react";

const ImageOr = (props) => {
    return <Box sx={{
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        textAlign: 'center'
    }}>{
        props.image ?


            <img
                src={props.image}
                alt={props.name}
                width="100%"
            /> :
            <Typography
                variant='h1' component='span'
                color="primary.main"
            >
                {props.index + 1}
            </Typography>
    }
    </Box>
}

const Text = (props) => {
    return <Box>
        <Typography
            variant='h3'
            color="primary.main"
        >
            {props.name}
        </Typography>
        <Typography
            variant='subtitle1'
        >
            {props.description}
        </Typography>
    </Box>;
}

const SideBySideTile = (props) => {
    return <>
        <Box sx={{
            display: {xs: 'none', sm: 'grid'},
            gridTemplateColumns: 'repeat(2, 1fr)',
            mb: 4,
            p: 4,
            borderRadius: 4,
            backgroundColor: 'background.light',
            columnGap: 3,
            rowGap: 1,
        }}>
            {props.index % 2 === 0 ?
                <ImageOr image={props.image} name={props.name} index={props.index}/> :
                <Text name={props.name} description={props.description}/>
            }

            {props.index % 2 === 0 ?
                <Text name={props.name} description={props.description}/> :
                <ImageOr image={props.image} name={props.name} index={props.index}/>
            }
        </Box>
        <Box sx={{
            display: {xs: 'grid', sm:'none'},
            gridTemplateColumns: 'repeat(1, 1fr)',
            mb: 4,
            p: 4,
            borderRadius: 4,
            backgroundColor: 'background.light',
            columnGap: 3,
            rowGap: 1,
        }}>
            <ImageOr image={props.image} name={props.name} index={props.index}/>
            <Text name={props.name} description={props.description}/>
        </Box>
    </>
}

export default SideBySideTile