import {Box, Link, Typography} from "@mui/material";
import * as React from "react";


const MadeWith = (props) => {
    return <div style={{width: "100%"}}>
        <Box sx={{
            margin: 'auto',
            color: 'secondary.main',
            backgroundColor: 'primary.main',
            p: 4,
        }}>
            <Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: 'center',
                    mb: 1
                }}>

                    <Typography variant={'subtitle1'}
                    >
                        Made with <Link href={'https://tryevergrow.com'}
                                        target="_blank"
                                        rel="noopener"
                                        fontWeight="bold"
                                        underline="none"
                                        color="inherit"
                    >Evergrow</Link>
                    </Typography>
                </Box>
            </Box>
        </Box>
    </div>
}

export default MadeWith