import * as React from "react";
import {Box, Typography} from "@mui/material";
import Testimonial from "../atoms/Testimonial";
import Carousel from 'react-material-ui-carousel'


const Testimonials = (props) => {

    return <>
        {props.testimonials?.length !== 0 ?

                <>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                        <Typography variant='h4' component='h2' color="primary.light">
                            What customers are saying
                        </Typography>
                    </Box>
                    <Box
                        key={'carusel-box'}
                        >
                        <Carousel key={'carusel'}
                                  indicators={false}
                                  transition={200}
                        >
                            {props.testimonials ?
                                props.testimonials.map((testimonial, index) => {
                                    return <Testimonial
                                        key={'testimonial-products-'+ index}
                                        who={testimonial.who}
                                        testimonial={testimonial.testimonial}
                                        image={props.images[index]?.node?.fluid?.src}
                                        index={index}
                                    />
                                })
                                : undefined
                            }
                        </Carousel>
                    </Box>
                </>

            : undefined
        }
    </>;
}

export default Testimonials