import * as React from "react";
import BigTile from "../atoms/BigTile";
import {Box, Typography} from "@mui/material";

const Products = (props) => {
    return <>
        {props.solutions?.length  !== 0 ?
            <>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    <Typography variant='h4' component='h2' color="primary.light">
                        Solutions
                    </Typography>
                </Box>
                {
                props.solutions.map((product, index) => {
                    return <BigTile
                        key={'solutions-products-'+ index}
                        name={product.name}
                        description={product.description}
                        image={props.images[index]?.node?.fluid?.src}
                    />
                })
            }
            </>
            : undefined
        }
    </>;
}

export default Products