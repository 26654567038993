import {Avatar, Box, Typography} from "@mui/material";
import * as React from "react";
import PersonIcon from '@mui/icons-material/Person';

const ImageOr = (props) => {
    return <Box sx={{
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        textAlign: 'center',
        mr: 2
    }}>
        <Avatar>{
            props.image ?
                <img
                    src={props.image}
                    alt={props.name}
                    width="100%"
                /> :
                <PersonIcon/>
        }
        </Avatar>
    </Box>
}

const Testimonial = (props) => {
    return <>
        <Box sx={{
            mb: 4,
            width: '100%',
            backgroundColor: 'background.light',
            borderRadius: 4,
            p: {xs: 2, md: 4},
            height: '310px',
            display: 'flex',
            justifyContent: "center",
            alignItems: "center",
            textAlign: 'center',
        }}>
            <Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: 'center',
                    mb: 1
                }}>
                    <Typography variant='subtitle1'>
                        {'"' + props.testimonial + '"'}
                    </Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: 'center',
                    my: 2
                }}>
                    <ImageOr image={props.image} name={props.who}/>
                    <Typography variant={'subtitle1'} fontWeight='700'>
                        {props.who}
                    </Typography>
                </Box>
            </Box>
        </Box>
    </>
}

export default Testimonial